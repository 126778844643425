<template>
  <header>
    <div class="nav">
      <div class="nav__wraper wraper">
        <nav class="nav__navigation">
          <div class="nav-container">
            <ul v-if="!isPersonalAccountPage" class="nav__link-block d-flex-rn">
              <li class="logo-block">
                <img src="../assets/x-pay-logo.svg" alt="" />
              </li>
              <li class="link-block__item">
                <div class="hamburger-menu">
                  <input id="menu__toggle" ref="menuToggle" type="checkbox" />
                  <label class="menu__btn" for="menu__toggle">
                    <span></span>
                  </label>
                  <div class="menu__box">
                    <div>
                      <ul>
                        <li>
                          <span
                            @click="gotoRoute('/')"
                            class="menu__item color-green"
                            >Home</span
                          >
                        </li>
                        <li>
                          <span
                            @click="gotoRoute('/PersonalBank')"
                            class="menu__item"
                            >Personal Bank Account</span
                          >
                        </li>
                        <li>
                          <span
                            @click="gotoRoute('/FirstStandard')"
                            class="menu__item"
                            >First Standard Ltd fees</span
                          >
                        </li>
                      </ul>
                    </div>
                    <div>
                      <ul>
                        <li>
                          <span
                            @click="gotoRoute('/OpenAccount')"
                            class="menu__item color-green"
                            >Open an account</span
                          >
                        </li>
                        <li>
                          <span
                            @click="gotoRoute('/BusinessBanking')"
                            class="menu__item"
                            >Business banking</span
                          >
                        </li>
                        <li>
                          <span
                            @click="gotoRoute('/AnonymousNumbered')"
                            class="menu__item"
                            >Anonymous bank account</span
                          >
                        </li>
                      </ul>
                    </div>
                    <div>
                      <ul>
                        <li>
                          <span
                            @click="gotoRoute('/Cryptocurrency')"
                            class="menu__item color-green"
                          >
                            Cryptocurrency
                          </span>
                        </li>
                        <li>
                          <span
                            @click="gotoRoute('/Paymentcard')"
                            class="menu__item color-green"
                          >
                            Payment card
                          </span>
                        </li>
                        <li>
                          <span
                            @click="gotoRoute('/Api')"
                            class="menu__item color-green"
                          >
                            Api
                          </span>
                        </li>
                        <!--                      <li>-->
                        <!--                        <span-->
                        <!--                          @click="gotoRoute('/About#TermsOfConditions')"-->
                        <!--                          class="menu__item"-->
                        <!--                        >-->
                        <!--                          {{lang.fileUpload2}}-->
                        <!--                        </span>-->
                        <!--                      </li>-->
                        <!--                      <li>-->
                        <!--                        <span-->
                        <!--                          @click="gotoRoute('/About#TermsOfConditions')"-->
                        <!--                          class="menu__item"-->
                        <!--                        >-->
                        <!--                          {{lang.fileUpload1}}-->
                        <!--                        </span>-->
                        <!--                      </li>-->
                        <!-- <li>
                        <span @click="gotoRoute('/Contact')" class="menu__item"
                          >Contact us</span
                        >
                      </li> -->
                      </ul>
                    </div>
                    <div>
                      <ul>
                        <li>
                          <span @click="gotoRoute('/login')" class="menu__item">
                            Online banking login</span
                          >
                        </li>
                        <li>
                          <span
                            @click="gotoRoute('/applicationForm')"
                            class="menu__item"
                            >Apply online</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <ul
              v-if="!isPersonalAccountPage"
              class="nav__link-block d-flex-rn align-items-center"
            >
              <li class="link-block__item display-block-none btn-transparent">
                <el-button @click="gotoRoute('/login')">LOGIN</el-button>
              </li>
              <li class="display-block-none">
                <el-button @click="gotoRoute('/applicationForm')"
                  >SIGN UP</el-button
                >
              </li>
            </ul>
            <div v-else class="nav__admin-block align-items-center">
              <div class="block-logo-login">
                <img src="../assets/x-pay-logo.svg" alt="" />
              </div>
              <ul class="nav__link-block d-flex-rn align-items-center">
                <li class="link-block__item ">
                  <span
                    @click="gotoRoute('/')"
                    :class="[
                      'header-item',
                      { 'header-item__active': $route.name === 'dashboard' },
                    ]"
                    >DASHBOARD</span
                  >
                </li>
                <li class="link-block__item ">
                  <span
                    @click="gotoRoute('/deposit/overview')"
                    :class="[
                      'header-item',
                      {
                        'header-item__active': $route.path.includes('deposit'),
                      },
                    ]"
                    >DEPOSIT</span
                  >
                </li>
                <li class="link-block__item ">
                  <span
                    @click="gotoRoute('/withdrawal/overview')"
                    :class="[
                      'header-item',
                      {
                        'header-item__active': $route.path.includes(
                          'withdrawal'
                        ),
                      },
                    ]"
                    >WITHDRAWAL</span
                  >
                </li>
                <li class="link-block__item ">
                  <span
                    @click="gotoRoute('/exchange')"
                    :class="[
                      'header-item',
                      { 'header-item__active': $route.name === 'exchange' },
                    ]"
                    >EXCHANGE</span
                  >
                </li>
                <li class="link-block__item ">
                  <span
                    @click="gotoRoute('/history')"
                    :class="[
                      'header-item',
                      { 'header-item__active': $route.name === 'history' },
                    ]"
                    >HISTORY</span
                  >
                </li>
                <li class="link-block__item ">
                  <span
                    @click="gotoRoute('/settings/bank-accounts')"
                    :class="[
                      'header-item',
                      {
                        'header-item__active': $route.path.includes('settings'),
                      },
                    ]"
                    >SETTINGS</span
                  >
                </li>
              </ul>
              <ul class="personal-area">
                <li class="block-login">
                  <div class="block-user">
                    <img src="../assets/navLogin/user.svg" alt="" />
                    <span>{{ userFullName }}</span>
                  </div>
                  <p class="btn-transparent" @click="logout()">
                    <el-button>EXIT</el-button>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      language: this.$store.state.language,
      scrollPosition: 0,
    };
  },

  mounted() {
    document.addEventListener("scroll", this.setScrollPosition);
  },

  methods: {
    setScrollPosition() {
      this.scrollPosition = window.scrollY;
    },

    selectLang(lang) {
      this.language = lang;
      this.$store.commit("setLang", lang);
    },

    gotoRoute(link) {
      //this.$refs.menuToggle.checked = false;
      if (link === "/login" && this.isAuth) {
        this.$router.push("/");
      } else {
        this.$router.push(link);
      }
    },

    logout() {
      this.$router.push("/login");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
    },
  },

  computed: {
    ...mapGetters(["lang"]),
    userFullName() {
      return this.$store.getters.fullName;
    },
    isAuth() {
      return !!this.$store.getters.user;
    },
    isPersonalAccountPage() {
      return [
        "dashboard",
        "overview",
        "bankTransfer",
        "cryptocurrency",
        "exchange",
        "history",
        "bankAccounts",
        "info",
        "2factor",
        "changePassword",
      ].includes(this.$route.name);
    },
  },

  beforeDestroy() {
    document.removeEventListener("scroll", this.setScrollPosition);
  },
};
</script>

<style lang="scss" scoped>
.nav {
  width: 100%;
}
.nav__navigation {
  width: 100%;
  min-height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  height: auto;
  background: #ffffff;
  @media screen and (max-width: 750px) {
    justify-content: space-around;
  }
}

.nav-container {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo-block {
  position: absolute;
  top: 24px;
  left: 165px;
  //cursor: pointer;
  @media screen and (max-width: 1300px) {
    left: 20px;
  }
  @media screen and (max-width: 380px) {
    position: relative;
    top: -15px;
  }
  img {
    @media screen and (max-width: 380px) {
      width: 80%;
    }
  }
}
.nav__logo-block {
  width: auto;
  height: 100%;
  position: absolute;
  top: 85px;
  cursor: pointer;

  @media screen and (max-width: 750px) {
    top: 80px;
    right: auto;
  }
}

.logo-img {
  width: auto;
  height: auto;
  margin-right: 13px;
}
.active-nav {
  color: #ff7f36;
}
.logo-text {
  font-size: 24px;
  line-height: 29px;
  //color: #ffffff;
  position: relative;
  cursor: pointer;
  @media screen and (max-width: 820px) {
    font-size: 22px;
  }
  &__hiden {
    top: -12px;
    right: -129px;
  }
}
.logo-text:after {
  content: "";
  width: 60px;
  height: 60px;
  //   background: url(../assets/header/x-pay-logo.svg);
  position: absolute;
  top: -73px;
  right: 93px;
  cursor: pointer;
}
.nav__link-block {
  display: flex;
  list-style-type: none;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  height: 18px;
}
@media screen and (max-width: 750px) {
  .nav__link-block:first-child {
    margin-right: auto;
  }
}

.link-block__item {
  margin-right: 26px;
  transition-duration: 0.1s;
  cursor: pointer;
  @media screen and (max-width: 520px) {
    margin-right: 5px;
  }
  span {
    font-family: "WorkSans-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    //color: #ffffff;
  }
}

a {
  text-decoration: none;
  color: inherit;
}
.nav-mobile {
  display: none;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
.el-dropdown-link {
  color: #000000;
}

/* mobile menu */
#menu__toggle {
  opacity: 0;
}
#menu__toggle:checked ~ .menu__btn {
  top: 0;
}
#menu__toggle:checked ~ .menu__btn > span {
  transform: rotate(45deg);
  //background: #ffffff;
  width: 20px;
  right: 15px;
}
#menu__toggle:checked ~ .menu__btn > span::before {
  top: 0;
  transform: rotate(0);
  //background: #ffffff;
  width: 20px;
}
#menu__toggle:checked ~ .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
  background: #ffffff;
  width: 20px;
}
#menu__toggle:checked ~ .menu__box {
  visibility: visible;
  left: 0;
}
#menu__toggle:checked ~ label {
  background: #033249;
  height: 104px;
  padding: 0px 10px;
}
.menu__btn {
  display: flex;
  align-items: center;
  position: fixed;
  top: 36px;
  right: 165px;
  width: 29px;
  height: 26px;
  cursor: pointer;
  z-index: 20;
  @media screen and (max-width: 1300px) {
    right: 20px;
  }
}
.nav-mobile__block {
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
  margin-right: 20px;
  margin-left: 20px;
}
.nav-mobile__text {
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  color: #b1b1b1;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.menu__btn > span {
  display: block;
  position: absolute;
  width: 32px;
  height: 2px;
  //background-color: #fff;
  transition-duration: 0.25s;
}
.menu__btn > span::before {
  display: block;
  position: absolute;
  width: 32px;
  height: 2px;
  //background-color: #fff;
  transition-duration: 0.25s;
}
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 32px;
  height: 2px;
  background-color: #fff;
  transition-duration: 0.25s;
}
.menu__btn > span::before {
  content: "";
  top: -8px;
}
.menu__btn > span::after {
  content: "";
  top: 8px;
}

.menu__box {
  display: flex;
  justify-content: center;
  position: fixed;
  visibility: hidden;
  top: 0;
  left: -100%;
  width: 100%;
  height: 50%;
  margin: 0;
  padding: 80px 0;
  list-style: none;
  background-color: #033249;
  box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.2);
  transition-duration: 0.25s;
  z-index: 10;
  @media screen and (max-width: 562px) {
    flex-direction: column;
    justify-content: flex-start;
  }
  ul {
    list-style-type: none;
    text-align: start;
    @media screen and (max-width: 820px) {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.header-item__active {
  color: #4a44f2;
}

.menu__item {
  display: block;
  padding: 12px 24px;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #4d4d4d;
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }
  @media screen and (max-width: 820px) {
    font-size: 15px;
    padding-right: 0;
    padding-bottom: 0;
  }
}
.menu__item:hover {
  background-color: #cfd8dc;
}
.block-user {
  display: flex;
  align-items: center;
  margin-right: 25px;
  span {
    font-family: "WorkSans-Regular";
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    //color: #ffffff;
    margin-left: 10px;
  }
}
.color-green {
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #385a41;
  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }
  @media screen and (max-width: 820px) {
    font-size: 15px;
    padding-right: 0;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 820px) {
  .display-block-none {
    display: none;
  }
}

.align-items-center {
  align-items: center;
  @media screen and (max-width: 400px) {
    flex-direction: column;
    margin-top: 0;
  }
}
.nav__admin-block {
  width: 100%;
  justify-content: space-around;
  display: flex;
}
.block-login {
  display: flex;
}
.btn-transparent .el-button {
  background: transparent;
  color: #4a44f2;
  border-color: #4a44fa;
}
.block-logo-login {
  margin-right: 32px;
}
.personal-area {
  margin-left: 100px;
}
</style>
