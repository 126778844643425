<template>
  <div class="transaction-wrapper">
    <div class="content">
      <!-- <div class="filter-wrapper">
        <el-select
          class="filter-wrapper__select"
          v-model="selectedAccount"
          filterable
          placeholder="Filter by account"
          value-key="currency"
        >
          <el-option
            v-for="item in accountTransactions"
            :key="item.currency"
            :label="item.currency"
            :value="item"
          >
          </el-option>
        </el-select>
        <div @click="printTable">
          <PrintIcon />
        </div>
      </div> -->
      <el-table :data="currentTransactions" empty-text="List empty">
        <el-table-column
          prop="date"
          label="Date and time"
          :formatter="getDate"
          sortable
        >
        </el-table-column>
        <el-table-column prop="debit" label="Coin"></el-table-column>
        <el-table-column prop="credit" label="Amount"></el-table-column>

        <el-table-column prop="status_name" label="Status" sortable>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
// import PrintIcon from '../../assets/svgIcons/print'

export default {
  data() {
    return {
      selectedAccount: null,
    };
  },

  mounted() {
    this.$store.dispatch("getAccountTransaction");
  },

  methods: {
    // getDate(row, column, cellValue) {
    //   return moment(cellValue)
    //     .format('YYYY-MM-DD')
    // },
    getBalance(row) {
      return `${row.balance} (${row.currency_abbreviation})`;
    },
    printTable() {
      window.print();
    },
  },

  computed: {
    accountTransactions() {
      return this.$store.getters.accountTransactions;
    },

    currentTransactions() {
      if (this.selectedAccount) return this.selectedAccount.transaction;
      return [];
    },
  },

  components: {
    // PrintIcon,
  },
};
</script>

<style lang="scss" scoped>
.transaction-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0;
  width: 890px;
  @media screen and (max-width: 1000px) {
    padding: 10px 0;
  }
  @media screen and (max-width: 950px) {
    width: 100%;
  }
}

@media screen and (max-width: 1450px) {
  .content {
    width: 600px;
  }
}
@media screen and (max-width: 1070px) {
  .content {
    width: 500px;
  }
}
@media screen and (max-width: 950px) {
  .content {
    margin-left: 0px !important;
    width: 90%;
  }
}
.filter-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 60px;
  padding: 6px;

  &__select {
    margin-right: 25px;
  }

  @media print {
    display: none;
  }
}
.content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}
</style>
