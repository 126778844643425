<template>
  <div class="intra-transfer-wrapper margin-bottom-120">
    <div class="change-password">
      <h2>CHANGE PASSWORD</h2>
      <span v-if="!firstInpError" class="change-password__label text"
        >Current password</span
      >
      <span v-if="firstInpError" class="error-message">{{ errorMessage }}</span>
      <el-input
        placeholder="Enter your current password"
        v-model="currentPassword"
        class="change-password__input"
        type="password"
        @input="firstInpError = false"
      />
      <span v-if="!secondInpError" class="change-password__label text"
        >New password</span
      >
      <span v-if="secondInpError" class="error-message">{{
        errorMessage
      }}</span>
      <el-input
        placeholder="Enter your new password"
        v-model="newPassword"
        class="change-password__input"
        type="password"
        @input="
          secondInpError = false;
          notMatch = false;
        "
      />
      <span v-if="!thirdInpError" class="change-password__label text"
        >Confirm password</span
      >
      <span v-if="thirdInpError" class="error-message">{{ errorMessage }}</span>
      <el-input
        placeholder="Confirm your new password"
        v-model="repeatNewPassword"
        class="change-password__input"
        type="password"
        @input="
          thirdInpError = false;
          notMatch = false;
        "
      />
      <span v-if="notMatch" class="error-message">
        The new password and password confirmations do not match
      </span>
      <el-button @click="changePassword" type="primary" class="change-button">
        CHANGE PASSWORD
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      repeatNewPassword: "",
      loadingChangeBtn: true,
      errorMessage: "The password contains less than 8 characters",
      firstInpError: false,
      secondInpError: false,
      thirdInpError: false,
      notMatch: false,
    };
  },

  created() {
    if (!this.$store.getters.user) {
      this.$store.commit("setUser", JSON.parse(sessionStorage.getItem("user")));
      if (!this.$store.getters.user) {
        this.$router.push("/login");
      }
    }
  },

  methods: {
    async changePassword() {
      const {
        firstInpError,
        secondInpError,
        thirdInpError,
        currentPassword,
        newPassword,
        repeatNewPassword,
      } = this;

      if (currentPassword.length < 8) this.firstInpError = true;
      if (newPassword.length < 8) this.firstInpError = true;
      if (repeatNewPassword.length < 8) this.firstInpError = true;
      if (firstInpError || secondInpError || thirdInpError) return;
      if (newPassword !== repeatNewPassword) {
        this.notMatch = true;
        return;
      }

      await this.$store.dispatch("changePassword", {
        oldPassword: currentPassword,
        newPassword,
      });
    },

    checkToken() {
      const token = sessionStorage.getItem("token");

      if (token) {
        const { exp } = JSON.parse(atob(token.split(".")[1]));

        if (exp * 1000 < new Date().getTime()) this.$router.push("/login");
      }
    },
  },

  computed: {
    ...mapGetters(["lang"]),
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>

<style lang="scss" scoped>
.intra-transfer-wrapper {
  @media screen and (max-width: 955px) {
    height: auto;
  }
}
.intra-transfer-wrapper {
  h2 {
    font-size: 22px;
    margin-bottom: 45px;
    @media screen and (max-width: 1320px) {
      text-align: center;
    }
    @media screen and (max-width: 1180px) {
      font-size: 18px;
    }
    @media screen and (max-width: 550px) {
      font-size: 14px;
    }
    @media screen and (max-width: 400px) {
      font-size: 11px;
    }
  }
}
.change-password {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 820px;
  padding: 25px 25px;
  font-family: "WorkSans-Regular";

  &__input {
    margin-bottom: 25px;
    width: 165px;
  }

  &__label {
    display: flex;
    margin-bottom: 5px;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: #000;
  }

  @media screen and (max-width: 955px) {
    width: 96%;
  }
}
.change-password__input {
  width: 395px;
}
.error-message {
  color: #cc3f55;
  font-size: 12px;
}

.change-button {
  margin-top: 25px;
}
.el-button {
  width: 200px;
  background: #4a44f2;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
</style>
