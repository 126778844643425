import Vue from "vue";
import Vuex from "vuex";
// import en from '../translates/en.js'
// import tr from '../translates/tr.js'
// import ru from '../translates/ru.js'

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    server: "https://api.clevelandinterbank.com:443",
    userName: null,
    pinCode: null,
    user: null,
    accountTransactions: [],
    loadingPage: true,
    rates: [],
    currencyRates: [],
    language: "EN",
    specialUserFlag: false,
  },
  getters: {
    // lang(state) {
    //   if (state.language === 'EN') return en
    //   if (state.language === 'TR') return tr
    //   if (state.language === 'RU') return ru
    //   return en
    // },
    fullName(state) {
      const { user } = state;

      if (!user) return "";

      return `${user.client_last_name} ${user.client_first_name}`;
    },
    user(state) {
      return state.user;
    },
    accountDetails(state) {
      if (!state.user) return [];

      return state.user.accounts;
    },
    accountTransactions(state) {
      return state.accountTransactions;
    },
    specialUserFlag(state) {
      return state.specialUserFlag;
    },
  },

  mutations: {
    setUserName(state, username) {
      state.userName = username;
    },

    setUser(state, user) {
      sessionStorage.setItem("user", JSON.stringify(user));
      state.user = user;
    },

    setAccountTransactions(state, accountTransactions) {
      state.accountTransactions = accountTransactions;
    },
    setLoadingPage(state, value) {
      state.loadingPage = value;
    },
    setRates(state, rates) {
      state.rates = rates;
    },
    setCurrencyRates(state, rates) {
      console.log(2, rates);
      state.currencyRates = rates;
      console.log("test-2", state.currencyRates);
    },
    setLang(state, lang) {
      state.language = lang;
      localStorage.setItem("lang", lang);
    },
    setSpecialUserFlag(state, param) {
      console.log("specialUserFlag", param);
      state.specialUserFlag = param;
    },
  },
  actions: {
    signIn({ state }, params) {
      return fetch(`${state.server}/api/login_check`, {
        method: "POST",
        body: JSON.stringify(params),
      }).then((response) => response.json());
    },

    changePassword({ state }, params) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      return fetch(`${state.server}/user/changes/passwords`, {
        method: "POST",
        headers,
        body: JSON.stringify(params),
      }).then((response) => response.json());
    },

    requestPinCode({ state }, token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      return fetch(`${state.server}/user/sends/users/pins`, {
        method: "POST",
        headers,
      })
        .then((response) => response.json())
        .then((response) => {
          state.pinCode = response[1];
          return response[1];
        });
    },

    sendPin({ state }, pin) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      return fetch(`${state.server}/user/verifications`, {
        method: "POST",
        body: JSON.stringify({ pin }),
        headers,
      }).then((response) => response.json());
    },

    userDetails({ state }) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      return fetch(`${state.server}/user/data`, {
        method: "GET",
        headers,
      }).then((response) => response.json());
    },

    getAccountTransaction({ state, commit }) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      return fetch(
        `${state.server}/api/transfeers/gets/users/accounts/details`,
        {
          method: "POST",
          headers,
        }
      )
        .then((response) => response.json())
        .then((data) => {
          commit("setAccountTransactions", data);
          return data;
        });
    },

    sendInterTransfer({ state }, params) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      return fetch(
        `${state.server}/api/transfeers/payments/requests/applications`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(params),
        }
      ).then((response) => response.json());
    },

    sendIntraTransfer({ state }, params) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      return fetch(`${state.server}/api/transfeers/internals/applications`, {
        method: "POST",
        headers,
        body: JSON.stringify(params),
      }).then((response) => response.json());
    },

    sendMessage({ state }, params) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      return fetch(`${state.server}/mailer/contacts/forms`, {
        method: "POST",
        headers,
        body: JSON.stringify(params),
      }).then((response) => response.json());
    },

    signUp({ state }, params) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      return fetch(`${state.server}/mailer/onlines/registrations`, {
        method: "POST",
        headers,
        body: JSON.stringify(params),
      }).then((response) => response.json());
    },

    getRates({ commit }) {
      const currenciesName = [
        "bitcoin",
        "ethereum",
        "tether",
        "ripple",
        "bitcoin-cash",
        "cardano",
        "bitcoin-sv",
        "chainlink",
        "litecoin",
        "binance-coin",
      ];
      const url =
        "https://api.coincap.io/v2/assets?ids=" + currenciesName.join();

      fetch(url, {
        method: "GET",
      })
        .then((response) => response.json())
        .then(({ data }) => {
          commit("setRates", data);
        });
    },
    getRatesCurrency({ commit }) {
      const currenciesName = [
        "USD",
        "EUR",
        "GBP",
        "RUB",
        "AED",
        "CHF",
        "JPY",
        "TRY",
      ];
      const url =
        "https://api.currencyfreaks.com/latest?apikey=68d80e85628442429ec84b543bbbad6c&symbols=" +
        currenciesName.join();

      fetch(url, {
        method: "GET",
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("data", data);
          commit("setCurrencyRates", data.rates);
        });
    },
  },
});

export default store;
