export const currencies = [
  {
    name: "BTC",
    fullName: "Bitcoin",
  },
  {
    name: "ETH",
    fullName: "Ethereum",
  },
  {
    name: "USDT",
    fullName: "Tether",
  },
  {
    name: "BCH",
    fullName: "Bitcoin Cash",
  },
  {
    name: "XRP",
    fullName: "Ripple",
  },
  {
    name: "ETC",
    fullName: "Ethereum Classic",
  },
  {
    name: "LTC",
    fullName: "Litecoin",
  },
];
