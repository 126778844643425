<template>
  <div class="table-wrapper">
    <el-table :data="currentTransactions" empty-text="List empty">
      <el-table-column prop="date" label="DATE" :formatter="getDate">
      </el-table-column>
      <el-table-column prop="transfer_type_name" label="TRANSACTION ID">
      </el-table-column>
      <el-table-column prop="transfer_type_name" label="TRANSACTION TYPE">
      </el-table-column>
      <el-table-column
        prop="account_special_number"
        label="STATUS"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="beneficiary_address" label="CURRENCY">
      </el-table-column>
      <el-table-column prop="credit" label="AMOUNT" align="center">
      </el-table-column>

      <el-table-column prop="status_name" label="DESTINATION ADDRESS / IBAN">
      </el-table-column>
      <el-table-column
        :formatter="getBalance"
        label="TRANSACTION HASH / INVOICE"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  computed: {
    accountDetails() {
      return this.$store.getters.accountDetails;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  font-family: "WorkSans-Regular";
  border-top: 1px solid #dddddd;
  width: 820px;
  min-height: 300px;
  margin-left: 30px;
  @media screen and (max-width: 1390px) {
    width: 500px;
    padding: 0;
  }
  @media screen and (max-width: 1000px) {
    width: 90%;
  }
}

.el-table__empty-block {
  min-height: 60px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7e7d9;
}
</style>
