<template>
  <div class="details-wrapper">
    <div class="user-wrapper">
      <div class="content">
        <h3>CLIENT DETAILS</h3>
        <h3>
          TOTAL AMOUNT <span class="text-blue">800.00</span>
          <el-select
            class="currency-select"
            default-first-option
            v-model="chosenCurrency"
            ><el-option
              v-for="item in currencies"
              :key="item"
              :value="item"
              :label="item"
            ></el-option
          ></el-select>
        </h3>
      </div>
      <div class="user-accounts-container">
        <div class="block">
          <span>CHOOSE ACCOUNT NUMBER</span>
          <el-select
            class="account-select"
            default-first-option
            v-model="chosenAccount"
          >
            <el-option
              v-for="item in accounts"
              :key="item"
              :value="item"
              :label="item"
            ></el-option>
          </el-select>
          <span>60.00 EUR</span>
        </div>
        <div class="block">
          <span>CHOOSE ACCOUNT NUMBER</span>
          <span class="text-blue bold">EUR 60.00</span>
          <span>EUR 60.00 AVAILABLE</span>
        </div>
        <div class="block">
          <span>Status <span class="bold">Active</span></span>
          <span>Currency <span class="bold">EUR</span></span>
        </div>
      </div>
    </div>
    <DashboardTable />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DashboardTable from "./DashboardTable.vue";

export default {
  data() {
    return {
      chosenAccount: "OL-6-162757208400338",
      accounts: [
        "OL-6-162757208400338",
        "OL-6-162757208400339",
        "OL-6-162757208400340",
      ],
      chosenCurrency: "EUR",
      currencies: ["EUR", "USD", "BTC"],
    };
  },

  created() {
    if (!this.$store.getters.user) {
      this.$store.commit("setUser", JSON.parse(sessionStorage.getItem("user")));
      if (!this.$store.getters.user) {
        this.$router.push("/login");
      }
    }
  },

  methods: {
    checkToken() {
      const token = sessionStorage.getItem("token");

      if (token) {
        const { exp } = JSON.parse(atob(token.split(".")[1]));

        if (exp * 1000 < new Date().getTime()) this.$router.push("/login");
      }
    },
  },

  computed: {
    ...mapGetters(["lang"]),
    user() {
      return this.$store.getters.user;
    },
  },

  components: {
    DashboardTable,
  },
};
</script>

<style lang="scss" scoped>
.details-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  margin-top: 90px;
  width: 100%;
  font-family: "WorkSans-Regular";
}

.user-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 1200px;
  @media screen and (max-width: 1390px) {
    width: 890px;
  }
  @media screen and (max-width: 1000px) {
    width: 90%;
  }
}

.text-blue {
  color: #4a44f2;
}

.user {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;

  &__key {
    width: 120px;
    display: block;
    padding-right: 49px;
    font-weight: 600;
    font-size: 15px;
    color: #000;
    text-align: left;
    @media screen and (max-width: 600px) {
      font-size: 12px;
    }
  }

  &__value {
    font-size: 16px;
    color: #000;
    text-align: left;

    @media screen and (max-width: 955px) {
      width: 50%;
    }
    @media screen and (max-width: 600px) {
      font-size: 12px;
    }
  }
}
.change-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 555px;
  background: rgba(228, 229, 215, 0.85);
  padding: 25px 25px;

  &__input {
    margin-bottom: 25px;
    width: 165px;
  }

  &__label {
    display: flex;
    margin-bottom: 5px;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: #000;
  }

  @media screen and (max-width: 955px) {
    width: 96%;
  }
}
.change-password__input {
  width: 395px;
}
.error-message {
  color: #cc3f55;
  font-size: 12px;
}

.bold {
  font-family: "WorkSans-Bold";
  font-size: 18px;
}

.change-button {
  margin-top: 25px;
}
.el-button {
  width: 150px;
  background: #385a41;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.el-table .cell {
  word-break: break-word !important;
}
.content {
  h3 {
    font-style: normal;
    font-weight: normal;
    font-family: "WorkSans-Bold";
    font-size: 22px;
    line-height: 27px;
    color: #000000;
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
}

.block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ebeff0;
  border-radius: 3px;
  margin-right: 30px;
  width: 300px;
  padding: 20px;
}

.user-accounts-container {
  display: flex;
  width: 100%;
  margin-top: 30px;
}

.account-select,
.currency-select {
  /deep/.el-input__inner {
    border: none !important;
    background-color: #fff !important;
    color: #000 !important;
    font-family: "WorkSans-Bold";
  }

  /deep/.el-select__caret {
    color: #000 !important;
  }
}

.currency-select {
  width: 90px;
  /deep/.el-input__inner {
    font-size: 22px;
  }
}

.account-select {
  width: 90%;
  /deep/.el-input__inner {
    font-size: 18px;
    padding: 0;
  }
}
</style>
