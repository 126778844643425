<template>
  <div class="account-wrapper">
    <div class="header">
      <div
        @click="$router.push('/deposit/overview')"
        :class="[
          'header-item',
          { 'header-item__active': $route.name === 'overview' },
        ]"
      >
        OVERVIEW
      </div>
      <div
        @click="$router.push('/deposit/bank-transfer')"
        :class="[
          'header-item',
          { 'header-item__active': $route.name === 'bankTransfer' },
        ]"
      >
        BANK TRANSFER
      </div>
      <div
        @click="$router.push('/deposit/cryptocurrency')"
        :class="[
          'header-item',
          { 'header-item__active': $route.name === 'cryptocurrency' },
        ]"
      >
        CRYPTOCURRENCY
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  async created() {
    this.checkToken();

    if (!this.$store.getters.user) {
      this.$store.commit("setUser", JSON.parse(sessionStorage.getItem("user")));
    }
    console.log("asdasd");
    await this.$store.dispatch("getAccountTransaction").then((data) => {
      if (data.code === 401) this.$router.push("/login");
    });
  },

  beforeUpdate() {
    this.checkToken();
  },

  methods: {
    checkToken() {
      const token = sessionStorage.getItem("token");

      if (token) {
        const { exp } = JSON.parse(atob(token.split(".")[1]));

        if (exp * 1000 < new Date().getTime()) this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.account-wrapper {
  display: flex;
  // flex-direction: column;
  //align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 200px;
}
.account-container {
  // background: url("../../assets/account/bg-account.png");
  width: 100%;
  // height: 460px;
  background-position: center;
}
.account__text {
  h1 {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-transform: uppercase;
    color: #ffffff;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
}
.header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 100%;
  max-width: 1920px;
  width: 100%;
}

.header {
  background: #fff;
  border-right: solid 1px #dddddd;
  // position: absolute;
  // top: 200px;
  // left: 0;

  @media print {
    display: none;
  }
  @media screen and (max-width: 1000px) {
    position: relative;
    top: 0;
    border: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 96%;
    padding-top: 100px;
  }
}

.header-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  font-family: "WorkSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  min-width: 241px;
  padding: 20px 0px 18px 0px;
  @media screen and (max-width: 600px) {
    font-size: 15px;
  }
  &__active {
    color: #4a44f2;
    font-family: "WorkSans-ExtraBold";
    background: #fff;
  }
  // @media screen and (max-width: 1236px) {
  //   min-width: auto;
  // }
  @media screen and (max-width: 955px) {
    border-radius: 20px;
    margin-bottom: 30px;
    border: 0.5px solid #385a41;
    min-width: 241px;
  }
}
.blockIcon {
  padding-right: 15px;
}
</style>
