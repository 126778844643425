<template>
  <div class="intra-transfer-wrapper margin-bottom-120">
    <h2>2 FACTOR</h2>

    <div v-if="!mode" class="form-wrapper">
      <span
        >TWO-FACTOR AUTHENTICATION IS
        <span v-if="!isEnabled" class="red">DISABLED</span
        ><span v-else class="green">ENABLED</span></span
      >

      <div class="button-wrapper">
        <el-button
          v-if="!isEnabled"
          :loading="loadingSubmitButton"
          @click="startEnable"
          type="primary"
        >
          ENABLE 2FA
        </el-button>
        <el-button
          v-else
          @click="changeEnabledStatus"
          :loading="loadingSubmitButton"
          type="primary"
        >
          DISABLE 2FA
        </el-button>
      </div>
    </div>
    <div v-else class="form-wrapper">
      <span>DOWNLOAD AND INSTALL THE GOOGLE AUTHENTICATOR APP</span>
      <div class="button-wrapper">
        <a><img src="@/assets/settings/appstore-download.png"/></a>
        <a><img src="@/assets/settings/googleplay-download.png"/></a>
      </div>
      <span>SCAN THIS QR CODE IN THE GOOGLE AUTHENTICATOR APP </span>
      <div class="button-wrapper">
        <qrcode-vue :value="qrcode" :size="230" level="H" />
      </div>
      <span
        >IF YOU ARE UNABLE TO SCAN THE QR CODE, PLEASE ENTER THIS CODE MANUALLY
        INTO THE APP</span
      >
      <div class="button-wrapper field">
        <el-input class="fields__input" v-model="qrcode" readonly
          ><el-button @click="copyQRCode" class="copy-button" slot="append">
            <img src="@/assets/copy-icon.svg"/></el-button
        ></el-input>
        <span style="color:#DA0000;">Please keep this Key in a safe place</span>
        <span
          >This Key will allow you to recover your Google Authenticator in case
          of phone loss</span
        >
      </div>
      <span>ENTER THE GENERATED CODE IN THE INPUT BELOW</span>
      <div class="button-wrapper">
        <el-input
          class="fields__input"
          placeholder="Generated QR Code"
          v-model="userQrcode"
        ></el-input>
      </div>
      <div class="button-wrapper">
        <el-button
          :loading="loadingSubmitButton"
          @click="cancelEnable"
          class="cancel-button"
        >
          CANCEL
        </el-button>
        <el-button
          @click="changeEnabledStatus"
          :loading="loadingSubmitButton"
          type="primary"
        >
          ENABLE 2FA
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import QrcodeVue from "qrcode.vue";

export default {
  data() {
    return {
      mode: null,
      isEnabled: false,
      firstSelected: null,
      secondSelected: null,
      amount: null,
      reference: null,
      checked: false,
      loadingSubmitButton: false,
      showErrorMessage: false,
      qrcode: "https://google.com",
      userQrcode: null,
    };
  },

  methods: {
    copyQRCode() {
      //change later
      navigator.clipboard.writeText(this.qrcode).then(() => {
        this.$message({
          message: "Copied to clipboard",
          type: "info",
        });
      });
    },

    cancelEnable() {
      this.mode = null;
    },
    startEnable() {
      this.mode = "enabling";
    },

    changeEnabledStatus() {
      this.isEnabled = !this.isEnabled;
      this.mode = null;
    },

    async sendTransfer() {
      const { firstSelected, secondSelected, amount, reference } = this;

      if (!firstSelected || !secondSelected || !amount || !reference) {
        this.showErrorMessage = true;
        this.$message({
          message: "all fields must be filled",
          type: "warning",
        });
        return;
      }
      this.loadingSubmitButton = true;

      await this.$store
        .dispatch("sendIntraTransfer", {
          amount,
          from_account: firstSelected,
          to_account: secondSelected,
          reference,
        })
        .then((data) => {
          if (data.code === 500 || data.message) {
            this.$message({
              message: data.message,
              type: "warning",
            });
          } else {
            this.firstSelected = null;
            this.secondSelected = null;
            this.amount = null;
            this.reference = null;
            this.checked = false;

            this.$message({
              message: "Payment order was created",
              type: "success",
            });
          }
          this.loadingSubmitButton = false;
        });
    },
  },

  computed: {
    ...mapGetters(["lang"]),
    accountDetails() {
      return this.$store.getters.accountDetails;
    },
  },

  components: {
    QrcodeVue,
  },
};
</script>

<style lang="scss" scoped>
.intra-transfer-wrapper {
  padding: 25px;
  font-family: "WorkSans-Regular";
  @media screen and (max-width: 955px) {
    height: auto;
  }
}
.intra-transfer-wrapper {
  h2 {
    font-size: 22px;
    margin: 15px;
    @media screen and (max-width: 1320px) {
      text-align: center;
    }
    @media screen and (max-width: 1180px) {
      font-size: 18px;
    }
    @media screen and (max-width: 550px) {
      font-size: 14px;
    }
    @media screen and (max-width: 400px) {
      font-size: 11px;
    }
  }
}
.form-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    margin: 15px;
  }

  @media screen and (max-width: 955px) {
    align-items: center;
    padding: 20px 10px;
  }
}

.fields {
  display: flex;
  margin-bottom: 25px;
  @media screen and (max-width: 1180px) {
    flex-direction: column;
  }
  &__select,
  &__input {
    width: 300px;
    margin-right: 25px;

    @media screen and (max-width: 955px) {
      width: 100%;
      margin-right: 0;
      margin-right: 0px;
      margin-left: 0px;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 955px) {
    flex-direction: column;
  }
}

.button-wrapper {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.el-button--primary {
  color: #fff;
  background: #4a44f2;
  border: 2px solid #4a44f2;
}

.field {
  display: flex;
  flex-direction: column;
}

.red {
  color: #da0000;
}

.green {
  color: #429e4b;
}

/deep/.el-input-group__append {
  border: none !important;
  border-bottom: 1px solid #dcdcdc !important;
  background-color: #fff !important;
}

/deep/.el-input__inner {
  font-family: "WorkSans-Bold";
  border: none;
  border-bottom: 1px solid #dcdcdc;
}

a {
  margin: 10px 10px 10px 0;
}

.cancel-button {
  border: 1px solid #4a44f2;
  color: #4a44f2;
  background: #fff;
}
</style>
