var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account-wrapper"},[_c('div',{staticClass:"header"},[_c('div',{class:[
        'header-item',
        { 'header-item__active': _vm.$route.name === 'bankAccounts' } ],on:{"click":function($event){return _vm.$router.push('/settings/bank-accounts')}}},[_vm._v(" BANK ACCOUNTS ")]),_c('div',{class:[
        'header-item',
        { 'header-item__active': _vm.$route.name === 'info' } ],on:{"click":function($event){return _vm.$router.push('/settings/info')}}},[_vm._v(" INFO AND DOCS ")]),_c('div',{class:[
        'header-item',
        { 'header-item__active': _vm.$route.name === '2factor' } ],on:{"click":function($event){return _vm.$router.push('/settings/2factor')}}},[_vm._v(" 2 FACTOR ")]),_c('div',{class:[
        'header-item',
        { 'header-item__active': _vm.$route.name === 'changePassword' } ],on:{"click":function($event){return _vm.$router.push('/settings/change-password')}}},[_vm._v(" CHANGE PASSWORD ")])]),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }