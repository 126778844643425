<template>
  <div class="intra-transfer-wrapper margin-bottom-120">
    <h2>BANK TRANSFER</h2>

    <div v-if="!mode" class="form-wrapper">
      <span>WHAT CURRENCY AND HOW MUCH WILL YOU DEPOSIT?</span>
      <div class="fields">
        <div class="field">
          <span>Select currency</span>
          <el-select
            class="fields__select"
            v-model="firstSelected"
            filterable
            placeholder="Select currency"
            no-data-text="List is empty"
          >
            <el-option
              v-for="item in accountDetails"
              :key="item.id"
              :label="
                `${item.account_special_number} (${item.currency_abbreviation}) (${item.balance})`
              "
              :value="item.account_special_number"
            >
            </el-option>
          </el-select>
        </div>
        <div class="field">
          <span>Amount</span>
          <el-input
            class="fields__input"
            placeholder="Amount"
            v-model="amount"
            type="number"
          />
        </div>
      </div>

      <div class="button-wrapper">
        <el-button
          @click="continueTransfer"
          :loading="loadingSubmitButton"
          type="primary"
        >
          CONTINUE
        </el-button>
      </div>
    </div>
    <div v-else class="form-wrapper">
      <h3>USE THIS INFORMATION TO TRANSFER MONEY FROM YOUR BANK</h3>
      <span
        >Please write your unique reference as your transfer message. We need
        this to match your transfer to your OERO account.</span
      >
      <div class="fields">
        <div class="field">
          <span>UNIQUE REFERENCE</span>
          <el-input
            class="fields__input"
            placeholder="Amount"
            v-model="amount"
            type="number"
            ><el-button
              @click="copyReference"
              class="copy-button"
              slot="append"
            >
              <img src="@/assets/copy-icon.svg"/></el-button
          ></el-input>
        </div>
        <div class="field">
          <span>SWIFT/BIC/ROUTING NUMBER</span>
          <el-input
            class="fields__input"
            placeholder="Amount"
            v-model="amount"
            type="number"
            ><el-button
              @click="copyBICNumber"
              class="copy-button"
              slot="append"
            >
              <img src="@/assets/copy-icon.svg"/></el-button
          ></el-input>
        </div>
        <div class="field">
          <span>BANK ACCOUNT NUMBER</span>
          <el-input
            class="fields__input"
            placeholder="Amount"
            v-model="amount"
            type="number"
            ><el-button
              @click="copyBankAccountNumber"
              class="copy-button"
              slot="append"
            >
              <img src="@/assets/copy-icon.svg"/></el-button
          ></el-input>
        </div>
      </div>

      <span>RECIPIENT INFORMATION</span>
      <span><b>OERO Europe S.A., 8-495 Alle America, USA</b></span>

      <span>Changed your mind? </span>
      <span
        >Don’t worry, you can just
        <span style="cursor:pointer;" @click="cancelTransfer" class="text-blue"
          >cancel you deposit announcement.</span
        ></span
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      firstSelected: null,
      secondSelected: null,
      amount: null,
      reference: null,
      checked: false,
      loadingSubmitButton: false,
      showErrorMessage: false,
      mode: null,
    };
  },

  methods: {
    copyBankAccountNumber() {
      //change later
      navigator.clipboard.writeText(this.amount).then(() => {
        this.$message({
          message: "Copied to clipboard",
          type: "info",
        });
      });
    },

    copyBICNumber() {
      //change later
      navigator.clipboard.writeText(this.amount).then(() => {
        this.$message({
          message: "Copied to clipboard",
          type: "info",
        });
      });
    },

    copyReference() {
      //change later
      navigator.clipboard.writeText(this.amount).then(() => {
        this.$message({
          message: "Copied to clipboard",
          type: "info",
        });
      });
    },

    cancelTransfer() {
      this.mode = null;
      this.firstSelected = null;
      this.amount = null;
    },
    continueTransfer() {
      const { firstSelected, amount } = this;

      if (!firstSelected || !amount) {
        this.showErrorMessage = true;
        this.$message({
          message: "all fields must be filled",
          type: "warning",
        });
        return;
      }
      this.mode = "continue";
    },
    async sendTransfer() {
      const { firstSelected, secondSelected, amount, reference } = this;

      if (!firstSelected || !secondSelected || !amount || !reference) {
        this.showErrorMessage = true;
        this.$message({
          message: "all fields must be filled",
          type: "warning",
        });
        return;
      }
      this.loadingSubmitButton = true;

      await this.$store
        .dispatch("sendIntraTransfer", {
          amount,
          from_account: firstSelected,
          to_account: secondSelected,
          reference,
        })
        .then((data) => {
          if (data.code === 500 || data.message) {
            this.$message({
              message: data.message,
              type: "warning",
            });
          } else {
            this.firstSelected = null;
            this.secondSelected = null;
            this.amount = null;
            this.reference = null;
            this.checked = false;

            this.$message({
              message: "Payment order was created",
              type: "success",
            });
          }
          this.loadingSubmitButton = false;
        });
    },
  },

  computed: {
    ...mapGetters(["lang"]),
    accountDetails() {
      return this.$store.getters.accountDetails;
    },
  },
};
</script>

<style lang="scss" scoped>
.intra-transfer-wrapper {
  padding: 25px;
  font-family: "WorkSans-Regular";
  @media screen and (max-width: 955px) {
    height: auto;
  }
}
.intra-transfer-wrapper {
  h2 {
    font-size: 22px;
    margin: 15px;
    @media screen and (max-width: 1320px) {
      text-align: center;
    }
    @media screen and (max-width: 1180px) {
      font-size: 18px;
    }
    @media screen and (max-width: 550px) {
      font-size: 14px;
    }
    @media screen and (max-width: 400px) {
      font-size: 11px;
    }
  }
}
.form-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    margin: 15px;
  }

  @media screen and (max-width: 955px) {
    align-items: center;
    padding: 20px 10px;
  }
}

/deep/.el-input__inner {
  font-family: "WorkSans-Bold";
  border: none;
  border-bottom: 1px solid #dcdcdc;
}

.fields {
  display: flex;
  margin-bottom: 25px;
  @media screen and (max-width: 1180px) {
    flex-direction: column;
  }
  &__select,
  &__input {
    width: 300px;
    margin-right: 25px;

    @media screen and (max-width: 955px) {
      width: 100%;
      margin-right: 0;
      margin-right: 0px;
      margin-left: 0px;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 955px) {
    flex-direction: column;
  }
}

.button-wrapper {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.el-button--primary {
  color: #fff;
  background: #4a44f2;
  border: 2px solid #4a44f2;
}

.field {
  display: flex;
  flex-direction: column;
}

.text-blue {
  color: #4a44f2;
}

.copy-button {
  background: #fff;
}

/deep/.el-input-group__append {
  border: none !important;
  border-bottom: 1px solid #dcdcdc !important;
  background-color: #fff !important;
}
</style>
