<template>
  <div class="details-wrapper">
    <div class="user-wrapper">
      <div class="content">
        <h2>BANK ACCOUNTS</h2>
      </div>
    </div>
    <div v-if="!mode" class="filter-wrapper">
      <div class="field">
        <el-input
          type="text"
          v-model="dateFrom"
          suffix-icon="el-icon-search"
          placeholder="Search"
        ></el-input>
      </div>
      <div class="field">
        <el-button @click="openAccount" type="primary">
          ADD ACCOUNT
        </el-button>
      </div>
    </div>

    <div v-if="!mode" class="table-wrapper">
      <el-table width="100%" :data="accountDetails" empty-text="List is empty">
        <el-table-column
          prop="account_special_number"
          label="Alias"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="account_type_name"
          label="Bank name"
          class-name="hide-for-mobile"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="currency_abbreviation"
          label="IBAN"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="balance" label="BIC" width="100" align="center">
        </el-table-column>
        <el-table-column
          prop="min_balance"
          label="Address"
          align="center"
          class-name="hide-for-mobile"
        >
        </el-table-column>
        <el-table-column
          prop="balance"
          label="Owner"
          align="center"
          width="100"
        >
        </el-table-column>
      </el-table>
    </div>
    <div v-if="mode === 'opened'" class="input-wrapper">
      <div class="fields">
        <div class="field">
          <span>Title (Alias)* </span><el-input class="fields__input" />
        </div>
        <div class="field">
          <span>Bank name</span><el-input class="fields__input" />
        </div>
      </div>
      <div class="fields">
        <div class="field">
          <span>BIC</span><el-input class="fields__input" />
        </div>
        <div class="field">
          <span>Account owner name</span><el-input class="fields__input" />
        </div>
      </div>
      <div class="fields">
        <div class="field">
          <span>IBAN</span><el-input class="fields__input" />
        </div>
        <div class="field">
          <span>Address</span><el-input class="fields__input" />
        </div>
      </div>
      <div class="fields">
        <el-button @click="cancelAccount" class="button-hollow">
          BACK
        </el-button>
        <el-button type="primary">
          CONFIRM
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingChangeBtn: true,
      mode: null,
    };
  },

  created() {
    if (!this.$store.getters.user) {
      this.$store.commit("setUser", JSON.parse(sessionStorage.getItem("user")));
    }
  },

  computed: {
    accountDetails() {
      return this.$store.getters.accountDetails;
    },
  },

  methods: {
    openAccount() {
      this.mode = "opened";
    },
    cancelAccount() {
      this.mode = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.details-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  width: 100%;
  font-family: "WorkSans-Regular";
}

.user-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 820px;
  @media screen and (max-width: 1390px) {
    width: 500px;
  }
  @media screen and (max-width: 1000px) {
    width: 90%;
  }
}

.user {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;

  &__key {
    width: 120px;
    display: block;
    padding-right: 49px;
    font-weight: 600;
    font-size: 15px;
    color: #000;
    text-align: left;
    @media screen and (max-width: 600px) {
      font-size: 12px;
    }
  }

  &__value {
    font-size: 16px;
    color: #000;
    text-align: left;

    @media screen and (max-width: 955px) {
      width: 50%;
    }
    @media screen and (max-width: 600px) {
      font-size: 12px;
    }
  }
}
.change-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 555px;
  background: rgba(228, 229, 215, 0.85);
  padding: 25px 25px;

  &__input {
    margin-bottom: 25px;
    width: 165px;
  }

  &__label {
    display: flex;
    margin-bottom: 5px;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: #000;
  }

  @media screen and (max-width: 955px) {
    width: 96%;
  }
}
.change-password__input {
  width: 395px;
}
.error-message {
  color: #cc3f55;
  font-size: 12px;
}

.change-button {
  margin-top: 25px;
}

.el-table .cell {
  word-break: break-word !important;
}
.content {
  h3 {
    font-family: "WorkSans-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: #000000;
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
}

.table-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  border-top: 1px solid #dddddd;
  width: 820px;
  @media screen and (max-width: 1390px) {
    width: 500px;
    padding: 0;
  }
  @media screen and (max-width: 1000px) {
    width: 90%;
  }
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  width: 820px;
  @media screen and (max-width: 1390px) {
    width: 500px;
    padding: 0;
  }
  @media screen and (max-width: 1000px) {
    width: 90%;
  }
}

.el-table__empty-block {
  min-height: 60px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7e7d9;
}

.el-table {
  background: #e7e7d9 !important;
}

.filter-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  max-width: 820px;
  border-radius: 60px;
  padding: 6px;

  &__select {
    margin-right: 25px;
  }

  @media print {
    display: none;
  }
}

.fields {
  display: flex;
  margin: 25px 0;
  @media screen and (max-width: 1180px) {
    flex-direction: column;
  }
  &__select,
  &__input {
    width: 400px;
    @media screen and (max-width: 955px) {
      width: 100%;
      margin-right: 0;
      margin-right: 0px;
      margin-left: 0px;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 955px) {
    flex-direction: column;
  }
}

.field {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
}

.el-button {
  border: 1px solid #4a44f2;
  color: #fff;
  background: #4a44f2;
  font-weight: 500;
  height: 52px;
  margin-top: 20px;
}

.button-hollow {
  background: #fff;
  color: #4a44f2;
  margin-right: 15px;
}
</style>
