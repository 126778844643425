<template>
  <div>
    <div class="login">
      <div class="header-wrapper">
        <div class="header__login">
          <div class="login__content">
            <div class="login__block">
              <div v-if="!isPinMode" class="form" @keyup.enter="signIn">
                <span class="header__title">Sign up into OERO</span>
                <!-- <p class="subtitle">
                  Please enter your Login and Password to access our exchange.
                </p> -->
                <p v-if="errorMessage" class="text error-message">
                  {{ errorMessage }}
                </p>
                <h1>Email*</h1>
                <el-input
                  placeholder
                  class="input"
                  type="login"
                  v-model="email"
                ></el-input>
                <h1>Name*</h1>
                <el-input
                  placeholder
                  class="input"
                  type="login"
                  v-model="name"
                ></el-input>
                <h1>Password*</h1>
                <el-input
                  placeholder
                  class="input"
                  type="password"
                  v-model="password"
                ></el-input>
                <h1>Confirm password*</h1>
                <el-input
                  placeholder
                  class="input"
                  type="password"
                  v-model="confirmPassword"
                ></el-input>
                <p class="text">
                  If you already got an account
                  <span @click="$router.push('/login')">LOGIN</span>
                </p>
                <div class="checkbox-wrapper"></div>
                <el-button
                  type="primary"
                  @click="signUp"
                  :loading="loadingSubmitButton"
                  >SIGN UP</el-button
                >
              </div>
              <!-- <div v-else class="form" @keyup.enter="confirmPin">
                <span class="error-message">Enter pin</span>
                <p v-if="errorMessage" class="text error-message">
                  {{ errorMessage }}
                </p>
                <h1>PIN CODE</h1>
                <el-input placeholder class="input" v-model="pin"></el-input>
                <div class="checkbox-wrapper"></div>
                <el-button
                  type="primary"
                  @click="confirmPin"
                  :loading="loadingConfirmButton"
                >
                  CONFIRM
                </el-button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      email: "",
      name: "",
      password: "",
      confirmPassword: "",
      isPinMode: false,
      pin: null,
      errorMessage: null,
      loadingSubmitButton: false,
      loadingConfirmButton: false,
    };
  },

  beforeRouteLeave(to, from, next) {
    this.$store.commit("setLoadingPage", true);
    setTimeout(() => {
      this.$store.commit("setLoadingPage", false);
    }, 1000);
    next();
  },

  computed: {
    ...mapGetters(["lang"]),
  },

  methods: {
    async signUp() {},

    async signIn() {
      if (this.loadingSubmitButton === true) return;

      if (!this.login.trim() || !this.password.trim()) {
        this.errorMessage = "Invalid login or password";
        return;
      }

      this.loadingSubmitButton = true;
      this.$store
        .dispatch("signIn", { username: this.login, password: this.password })
        .then((response) => {
          if (response.code) throw response.message;
          this.isPinMode = true;
          sessionStorage.setItem("token", response.token);
          this.$store.commit("setUserName", this.login);
          this.$store.dispatch("requestPinCode", response.token);
          this.loadingSubmitButton = false;
          this.errorMessage = null;
        })
        .catch((error) => {
          this.errorMessage = error;
          this.loadingSubmitButton = false;
        });
    },

    confirmPin() {
      if (this.loadingConfirmButton) return;

      this.loadingConfirmButton = true;
      if (!this.pin) {
        this.errorMessage = "Invalid pin";
        this.loadingConfirmButton = false;
        return;
      }
      this.$store
        .dispatch("sendPin", this.pin)
        .then((response) => {
          if (response === "PIN IS NOT VALID") {
            this.errorMessage = "PIN IS NOT VALID";
          } else {
            this.$store.commit("setUser", response);
            this.errorMessage = false;
            this.isPinMode = false;
            this.$router.push("/");
          }
        })
        .catch((error) => {
          console.error(error);
          this.errorMessage = "Invalid pin";
        })
        .finally(() => {
          this.loadingConfirmButton = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  margin-bottom: -120px;
  padding-bottom: 150px;
  @media screen and (max-width: 1616px) {
    padding-bottom: 60px;
  }
  @media screen and (max-width: 1200px) {
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media screen and (max-width: 750px) {
    padding-bottom: 130px;
  }
}
.login__content {
  display: flex;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
}
.header-wrapper {
  width: 100%;
  max-width: 1170px;
  height: auto;
  margin: 0 auto;
  padding-top: 104px;
}
.stripes__block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 675px;
  // background: url("../../assets/onlineBanking/stripesBG.jpg");
  @media (max-width: 1200px) {
    display: none;
  }
  div:first-child {
    align-self: flex-start;
    height: 50%;
    width: 50%;
    // padding-top: 79px;
  }
  div:last-child {
    height: 100%;
  }
}
.header__title {
  font-family: "WorkSans-Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  color: #033249;
  margin-bottom: 16px;
  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 24px;
  }
}
.subtitle {
  font-family: "WorkSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #8692a6;
  padding-bottom: 16px;
  border-bottom: 1px solid #ebeff0;
  margin-bottom: 24px;
}

.text {
  font-family: "WorkSans-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #696f79;

  span {
    color: #4a44f2;
    font-weight: bold;
    cursor: pointer;
  }
}
.header__login {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  position: relative;
}
.header__login-fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.login__icons {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  list-style-type: none;
  padding-right: 50px;
  @media screen and (max-width: 1384px) {
    font-size: 12px;
  }
  @media screen and (max-width: 550px) {
    padding-right: 10px;
    width: 300px;
  }
  img {
    @media screen and (max-width: 750px) {
      width: 20%;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 675px;
  padding: 70px 76px;
  box-sizing: border-box;
  @media screen and (max-width: 1200px) {
    align-items: center;
    margin: 0 auto;
    height: 100%;
  }
  @media screen and (max-width: 600px) {
    padding: 70px 20px;
  }
  h1 {
    font-family: "WorkSans-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #696f79;
    margin-bottom: 14px;
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
  .input {
    width: 395px;
    height: 40px;
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  .checkbox-wrapper {
    display: flex;
    margin-bottom: 40px;
  }
}

.error-message {
  color: #cc3f55;
}
.el-button--primary {
  background: #4a44f2;
  border-color: #4a44f2;
  width: 100%;
}
.form .input {
  margin-bottom: 30px;
}
.login-img-block {
  width: 100% !important;
  text-align: center;
  // background: url("../../assets/onlineBanking/onlineBankingBg.jpg");
  img {
    width: 91%;
  }
}
.el-button--primary {
  color: #fff;
}
</style>
