<template>
  <div class="table-wrapper">
    <el-table :data="accountDetails" stripe empty-text="List is empty">
      <el-table-column
        sortable
        prop="currency_abbreviation"
        label="COIN"
        align="center"
      ></el-table-column>
      <el-table-column
        sortable
        prop="account_special_number"
        label="ACCOUNT NUMBER"
        width="180"
      >
      </el-table-column>
      <el-table-column sortable prop="balance" label="TOTAL" align="center">
      </el-table-column>
      <el-table-column sortable prop="balance" label="AVAILABLE" align="center">
      </el-table-column>
      <el-table-column sortable prop="balance" label="IN ORDER" align="center">
      </el-table-column>
      <el-table-column sortable prop="balance" label="BTC VALUE" align="center">
      </el-table-column>
      <el-table-column
        prop="min_balance"
        label="ACTION"
        align="center"
        class-name="hide-for-mobile"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  computed: {
    accountDetails() {
      return this.$store.getters.accountDetails;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  border-top: 1px solid #dddddd;
  width: 1200px;
  @media screen and (max-width: 1390px) {
    width: 890px;
    padding: 0;
  }
  @media screen and (max-width: 1000px) {
    width: 90%;
  }
}

.el-table__empty-block {
  min-height: 60px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7e7d9;
}

.el-table {
  background: #e7e7d9 !important;
  font-family: "WorkSans-Regular";
}
</style>
