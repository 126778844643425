<template>
  <div
    v-if="!mode || mode === 'confirm'"
    class="intra-transfer-wrapper margin-bottom-120"
  >
    <h2>EXCHANGE BETWEEN ACCOUNTS</h2>

    <div class="form-wrapper">
      <span>WHAT CURRENCY AND HOW MUCH WILL YOU EXCHANGE?</span>
      <div class="fields">
        <div class="field">
          <span>DEBIT FROM</span>
          <div class="block">
            <span>CHOOSE ACCOUNT NUMBER</span>
            <el-select
              class="account-select"
              default-first-option
              v-model="firstSelected"
              placeholder="Select from"
            >
              <el-option
                v-for="item in accounts"
                :key="item"
                :value="item"
                :label="item"
              ></el-option>
            </el-select>
            <span>60.00 EUR</span>
          </div>
        </div>
        <div class="field">
          <span>CREDIT TO</span>
          <div class="block">
            <span>CHOOSE ACCOUNT NUMBER</span>
            <el-select
              class="account-select"
              default-first-option
              v-model="secondSelected"
              placeholder="Select to"
            >
              <el-option
                v-for="item in accounts"
                :key="item"
                :value="item"
                :label="item"
              ></el-option>
            </el-select>
            <span>60.00 EUR</span>
          </div>
        </div>
      </div>

      <div class="fields">
        <div v-if="!mode" class="field">
          <span>From</span>
          <el-input
            class="fields__input"
            placeholder="From"
            v-model="amountFrom"
            type="number"
            ><template slot="prepend">EUR</template></el-input
          >
        </div>
        <div v-else-if="mode === 'confirm'" class="field">
          <span>Amount to transfer</span>
          <span>EUR {{ amountFrom }}</span>
        </div>
      </div>

      <div class="fields">
        <div v-if="!mode" class="field">
          <span>To</span>
          <el-input
            class="fields__input"
            placeholder="To"
            v-model="amountTo"
            type="number"
            ><template slot="prepend">BTC</template></el-input
          >
        </div>
        <div v-else-if="mode === 'confirm'" class="field">
          <span>Converted amount</span>
          <span>BTC {{ amountTo }}</span>
        </div>
      </div>

      <div class="fields">
        <div v-if="!mode" class="field">
          <span>Description</span>
          <el-input
            class="fields__input"
            :rows="5"
            resize="none"
            placeholder="Description"
            v-model="reference"
            type="textarea"
          ></el-input>
        </div>
      </div>

      <div v-if="!mode" class="button-wrapper">
        <el-button
          @click="confirmTransfer"
          :loading="loadingSubmitButton"
          type="primary"
        >
          CONTINUE
        </el-button>
      </div>
      <div v-else-if="mode === 'confirm'" class="button-wrapper">
        <el-button
          @click="cancelTransfer"
          :loading="loadingSubmitButton"
          class="cancel-button"
        >
          BACK
        </el-button>
        <el-button
          @click="sendTransferMock"
          :loading="loadingSubmitButton"
          type="primary"
        >
          CONFIRM
        </el-button>
      </div>
    </div>
  </div>
  <div v-else class="intra-transfer-wrapper margin-bottom-120">
    <div class="central-block">
      <img src="@/assets/ok-sign.svg" class="ok-sign" />
      <h3>EXCHANGE HAS BEEN EXECUTED SUCCESSFULLY</h3>
      <span @click="cancelTransfer" style="cursor:pointer;" class="text-blue"
        >Back to exchange</span
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      mode: null,
      firstSelected: null,
      secondSelected: null,
      amountFrom: null,
      amountTo: null,
      reference: null,
      loadingSubmitButton: false,
      showErrorMessage: false,
      accounts: [
        "OL-6-162757208400338",
        "OL-6-162757208400339",
        "OL-6-162757208400340",
      ],
    };
  },

  methods: {
    cancelTransfer() {
      this.mode = null;
      this.amountFrom = null;
      this.amountTo = null;
      this.firstSelected = null;
      this.secondSelected = null;
    },

    sendTransferMock() {
      this.mode = "done";
    },

    confirmTransfer() {
      const { firstSelected, secondSelected, amountFrom, amountTo } = this;

      if (!firstSelected || !secondSelected || !amountFrom || !amountTo) {
        this.showErrorMessage = true;
        this.$message({
          message: "all fields must be filled",
          type: "warning",
        });
        return;
      }

      this.mode = "confirm";
    },

    async sendTransfer() {
      const { firstSelected, secondSelected, amount, reference } = this;

      if (!firstSelected || !secondSelected || !amount || !reference) {
        this.showErrorMessage = true;
        this.$message({
          message: "all fields must be filled",
          type: "warning",
        });
        return;
      }
      this.loadingSubmitButton = true;

      await this.$store
        .dispatch("sendIntraTransfer", {
          amount,
          from_account: firstSelected,
          to_account: secondSelected,
          reference,
        })
        .then((data) => {
          if (data.code === 500 || data.message) {
            this.$message({
              message: data.message,
              type: "warning",
            });
          } else {
            this.firstSelected = null;
            this.secondSelected = null;
            this.amount = null;
            this.reference = null;
            this.checked = false;

            this.$message({
              message: "Payment order was created",
              type: "success",
            });
          }
          this.loadingSubmitButton = false;
        });
    },
  },

  computed: {
    ...mapGetters(["lang"]),
    accountDetails() {
      return this.$store.getters.accountDetails;
    },
  },
};
</script>

<style lang="scss" scoped>
.intra-transfer-wrapper {
  padding: 25px;
  font-family: "WorkSans-Regular";
  max-width: 1200px;
  margin: 100px auto;
  @media screen and (max-width: 955px) {
    height: auto;
  }
}
.intra-transfer-wrapper {
  h2 {
    font-size: 22px;
    @media screen and (max-width: 1320px) {
      text-align: center;
    }
    @media screen and (max-width: 1180px) {
      font-size: 18px;
    }
    @media screen and (max-width: 550px) {
      font-size: 14px;
    }
    @media screen and (max-width: 400px) {
      font-size: 11px;
    }
  }
}
.form-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    margin: 15px 0;
  }

  @media screen and (max-width: 955px) {
    align-items: center;
    padding: 20px 10px;
  }
}

.fields {
  display: flex;
  margin-bottom: 25px;
  @media screen and (max-width: 1180px) {
    flex-direction: column;
  }
  &__select,
  &__input {
    margin-right: 25px;
    width: 500px;
    @media screen and (max-width: 955px) {
      width: 100%;
      margin-right: 0;
      margin-right: 0px;
      margin-left: 0px;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 955px) {
    flex-direction: column;
  }
}

.button-wrapper {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.el-button--primary {
  color: #fff;
  background: #4a44f2;
  border: 2px solid #4a44f2;
}

.field {
  display: flex;
  flex-direction: column;
  span {
    margin: 15px 0;
  }
}

.align-self-end {
  align-self: flex-end;
}

.block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ebeff0;
  border-radius: 3px;
  margin-right: 30px;
  width: 300px;
  padding: 20px;
}

.account-select,
.currency-select {
  /deep/.el-input__inner {
    border: none !important;
    background-color: #fff !important;
    color: #000 !important;
    font-family: "WorkSans-Bold";
  }

  /deep/.el-select__caret {
    color: #000 !important;
  }
}

.currency-select {
  width: 90px;
  /deep/.el-input__inner {
    font-size: 22px;
  }
}

.account-select {
  width: 90%;
  /deep/.el-input__inner {
    font-size: 18px;
    padding: 0;
  }
}

.central-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  width: 400px;
  height: 230px;
  padding: 15px;
  margin: 50px auto;
}

.ok-sign {
  width: 42px;
  height: 42px;
}
.text-blue {
  color: #4a44f2;
}

.cancel-button {
  border: 1px solid #4a44f2;
  color: #4a44f2;
  background: #fff;
}
</style>
