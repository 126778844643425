<template>
  <div class="intra-transfer-wrapper margin-bottom-120">
    <h2>CRYPTOCURRENCY DEPOSIT</h2>

    <div class="form-wrapper">
      <div class="fields">
        <div class="field">
          <span>Select cryptocurrency</span>
          <el-select
            class="fields__select"
            v-model="firstSelected"
            filterable
            placeholder="Select currency"
            no-data-text="List is empty"
          >
            <el-option
              v-for="item in currencies"
              :key="item.name"
              :label="`${item.name} ${item.fullName}`"
              :value="item.name"
            >
              <img :src="`/${item.name}.svg`" />
              <span>{{ item.name }} {{ item.fullName }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="fields">
        <div class="field">
          <span>Send your {{ firstSelected }} to this address</span>
          <el-input
            class="fields__input"
            placeholder="Address for sending"
            v-model="address"
            readonly
            ><el-button @click="copyAddress" class="copy-button" slot="append">
              <img src="@/assets/copy-icon.svg"/></el-button
          ></el-input>
          <p>
            ONLY {{ firstSelected }} MAY BE SENT TO THIS ADDRESS. PAYMENTS SENT
            IN ANY OTHER CRUPTOCURRENCY WILL NOT BE CREDITED.
          </p>
        </div>
      </div>

      <!-- <div class="button-wrapper">
        <el-button
          @click="sendTransfer"
          :loading="loadingSubmitButton"
          type="primary"
        >
          CONTINUE
        </el-button>
      </div> -->
      <CryptoDepositsTable />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CryptoDepositsTable from "./CryptoDepositsTable.vue";
import { currencies } from "@/utils/currencies.js";

export default {
  data() {
    return {
      firstSelected: null,
      secondSelected: null,
      address: "ASDASDASDASDASD",
      reference: null,
      checked: false,
      loadingSubmitButton: false,
      showErrorMessage: false,
      currencies: currencies,
    };
  },

  components: {
    CryptoDepositsTable,
  },

  methods: {
    copyAddress() {
      //change later
      navigator.clipboard.writeText(this.address).then(() => {
        this.$message({
          message: "Copied to clipboard",
          type: "info",
        });
      });
    },

    async sendTransfer() {
      const { firstSelected, secondSelected, amount, reference } = this;

      if (!firstSelected || !secondSelected || !amount || !reference) {
        this.showErrorMessage = true;
        this.$message({
          message: "all fields must be filled",
          type: "warning",
        });
        return;
      }
      this.loadingSubmitButton = true;

      await this.$store
        .dispatch("sendIntraTransfer", {
          amount,
          from_account: firstSelected,
          to_account: secondSelected,
          reference,
        })
        .then((data) => {
          if (data.code === 500 || data.message) {
            this.$message({
              message: data.message,
              type: "warning",
            });
          } else {
            this.firstSelected = null;
            this.secondSelected = null;
            this.amount = null;
            this.reference = null;
            this.checked = false;

            this.$message({
              message: "Payment order was created",
              type: "success",
            });
          }
          this.loadingSubmitButton = false;
        });
    },
  },

  computed: {
    ...mapGetters(["lang"]),
    accountDetails() {
      return this.$store.getters.accountDetails;
    },
  },
};
</script>

<style lang="scss" scoped>
.intra-transfer-wrapper {
  padding: 25px;
  font-family: "WorkSans-Regular";
  @media screen and (max-width: 955px) {
    height: auto;
  }
}
.intra-transfer-wrapper {
  h2 {
    font-size: 22px;
    margin: 15px;
    @media screen and (max-width: 1320px) {
      text-align: center;
    }
    @media screen and (max-width: 1180px) {
      font-size: 18px;
    }
    @media screen and (max-width: 550px) {
      font-size: 14px;
    }
    @media screen and (max-width: 400px) {
      font-size: 11px;
    }
  }
}
.form-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    margin: 15px;
  }

  @media screen and (max-width: 955px) {
    align-items: center;
    padding: 20px 10px;
  }
}

.fields {
  display: flex;
  margin-bottom: 25px;
  @media screen and (max-width: 1180px) {
    flex-direction: column;
  }
  &__select,
  &__input {
    width: 300px;
    margin-right: 25px;

    @media screen and (max-width: 955px) {
      width: 100%;
      margin-right: 0;
      margin-right: 0px;
      margin-left: 0px;
      margin-bottom: 10px;
    }
  }
  &__input {
    /deep/.el-input__inner {
      color: #4a44fa;
      font-family: "WorkSans-Bold";
      border: none;
      border-bottom: 1px solid #dcdcdc;
    }
  }
  @media screen and (max-width: 955px) {
    flex-direction: column;
  }
}

.button-wrapper {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.el-button--primary {
  color: #fff;
  background: #4a44f2;
  border: 2px solid #4a44f2;
}

.field {
  display: flex;
  flex-direction: column;
  p {
    width: 300px;
  }
}

/deep/.el-input-group__append {
  border: none !important;
  border-bottom: 1px solid #dcdcdc !important;
  background-color: #fff !important;
}

.el-select-dropdown__item {
  display: flex;
  align-items: center;
  & > * {
    margin-right: 10px;
  }
}
</style>
