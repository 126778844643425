<template>
  <div class="transaction-wrapper">
    <div class="content">
      <div class="filter-wrapper">
        <div class="field">
          <span>FILTER BY THE ACCOUNT</span>
          <el-select
            class="filter-wrapper__select"
            v-model="selectedAccount"
            filterable
            placeholder="Filter by account"
            value-key="currency"
          >
            <!-- {{accountTransactions}} -->
            <el-option
              v-for="item in accountTransactions"
              :key="item.currency"
              :label="item.currency"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="field">
          <span>DATE FROM</span>
          <el-date-picker
            type="date"
            v-model="dateFrom"
            placeholder="Date from"
          ></el-date-picker>
        </div>
        <div class="field">
          <span>DATE TO</span>
          <el-date-picker
            type="date"
            v-model="dateTo"
            placeholder="Date to"
          ></el-date-picker>
          <!-- <div @click="printTable">
          <PrintIcon />
        </div> -->
        </div>
        <div class="field">
          <el-button>
            GET STATEMENT
          </el-button>
        </div>
      </div>
      <el-table
        :data="currentTransactions"
        style="width: 100%"
        empty-text="List empty"
      >
        <el-table-column
          prop="date"
          label="Date"
          :formatter="getDate"
          sortable
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="transfer_type_name"
          label="Transfer Type"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="account_special_number"
          label="Sender"
          align="center"
          width="80"
        >
        </el-table-column>
        <el-table-column
          prop="beneficiary_address"
          label="Beneficiary"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="transfer_number"
          label="Transaction Number"
          align="center"
          width="160"
        >
        </el-table-column>

        <el-table-column
          prop="debit"
          label="Debit"
          align="center"
          width="100%"
        ></el-table-column>
        <el-table-column
          prop="credit"
          label="Credit"
          align="center"
          width="100%"
        ></el-table-column>

        <el-table-column
          prop="status_name"
          label="Status"
          width="100%"
          sortable
        >
        </el-table-column>
        <el-table-column :formatter="getBalance" label="Balance" width="100%">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
// import PrintIcon from '../../assets/svgIcons/print'

export default {
  data() {
    return {
      selectedAccount: null,
      dateFrom: new Date(),
      dateTo: new Date(),
    };
  },

  mounted() {
    this.$store.dispatch("getAccountTransaction").then((data) => {
      if (data.code === 401) this.$router.push("/login");
    });
  },

  methods: {
    // getDate(row, column, cellValue) {
    //   return moment(cellValue)
    //     .format('YYYY-MM-DD')
    // },
    getBalance(row) {
      return `${row.balance} (${row.currency_abbreviation})`;
    },
    printTable() {
      window.print();
    },
  },

  computed: {
    accountTransactions() {
      return this.$store.getters.accountTransactions;
    },

    currentTransactions() {
      if (this.selectedAccount) return this.selectedAccount.transaction;
      return [];
    },
  },

  components: {
    // PrintIcon,
  },
};
</script>

<style lang="scss" scoped>
.transaction-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "WorkSans-Regular";
  padding: 70px 0;
  margin-top: 120px;
  @media screen and (max-width: 1000px) {
    padding: 10px 0;
  }
  @media screen and (max-width: 950px) {
    width: 100%;
  }
}

@media screen and (max-width: 1450px) {
  .content {
    width: 600px;
  }
}
@media screen and (max-width: 1070px) {
  .content {
    width: 500px;
  }
}
@media screen and (max-width: 950px) {
  .content {
    margin-left: 0px !important;
    width: 90%;
  }
}
.filter-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 60px;
  padding: 6px;

  &__select {
    margin-right: 25px;
  }

  @media print {
    display: none;
  }
}
.content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 100px;
}

.field {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
}

.el-button {
  border: 1px solid #4a44f2;
  color: #4a44f2;
  font-weight: 500;
  height: 52px;
  margin-top: 20px;
}
</style>
