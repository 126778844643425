<template>
  <div class="transaction-wrapper">
    <div class="content">
      <h2>INFO AND DOCS</h2>
      <div class="file-block">
        <img src="@/assets/settings/docs-icon.svg" />
        <div class="file-text">
          <span class="file-name">PROOF OF RESIDENCE</span>
          <span class="status yellow">pending</span><br />
          Provide a document showing your name and address - bank statement,
          utility bill etc.
        </div>
      </div>
      <div class="file-block">
        <img src="@/assets/settings/docs-icon.svg" />
        <div class="file-text">
          <span class="file-name">PROOF OF CRYPTO</span>
          <span class="status green">verified</span><br />
          Provide documents such as deposits and trading history at other
          exchanges.
        </div>
      </div>
      <div class="file-block">
        <img src="@/assets/settings/docs-icon.svg" />
        <div class="file-text">
          <span class="file-name">SOURCE OF FUNDS</span>
          <span class="status red">declined</span><br />Provide documents such
          as salary pay slips, tax statements, investment portfolio etc.
        </div>
      </div>
      <div class="file-block">
        <img src="@/assets/settings/docs-icon.svg" />
        <div class="file-text">
          <span class="file-name">ADDITIONAL INFO</span><br />Please contact
          support, if you want to make any changes. View
        </div>
        <div class="btn-block">
          <el-button type="button">UPLOAD </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
// import PrintIcon from '../../assets/svgIcons/print'

export default {
  data() {
    return {
      selectedAccount: null,
    };
  },

  mounted() {
    this.$store.dispatch("getAccountTransaction");
  },

  methods: {
    // getDate(row, column, cellValue) {
    //   return moment(cellValue)
    //     .format('YYYY-MM-DD')
    // },
    getBalance(row) {
      return `${row.balance} (${row.currency_abbreviation})`;
    },
    printTable() {
      window.print();
    },
  },

  computed: {
    accountTransactions() {
      return this.$store.getters.accountTransactions;
    },

    currentTransactions() {
      if (this.selectedAccount) return this.selectedAccount.transaction;
      return [];
    },
  },

  components: {
    // PrintIcon,
  },
};
</script>

<style lang="scss" scoped>
.transaction-wrapper {
  display: flex;
  flex-direction: column;
  font-family: "WorkSans-Regular";
  margin-left: 30px;
  width: 890px;
  @media screen and (max-width: 1000px) {
    padding: 10px 0;
  }
  @media screen and (max-width: 950px) {
    width: 100%;
  }
}

@media screen and (max-width: 1450px) {
  .content {
    width: 600px;
  }
}
@media screen and (max-width: 1070px) {
  .content {
    width: 500px;
  }
}
@media screen and (max-width: 950px) {
  .content {
    margin-left: 0px !important;
    width: 90%;
  }
}
.filter-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 60px;
  padding: 6px;

  &__select {
    margin-right: 25px;
  }

  @media print {
    display: none;
  }
}
.content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  h2 {
    margin-bottom: 45px;
  }
}

.file-block {
  display: flex;
  width: 100%;
  margin: 10px 0;
  img {
    margin-right: 30px;
  }
}

.file-text {
  width: 60%;
}

.file-name {
  font-weight: 600;
}
.el-button--button {
  background: #4a44f2;
  border: 2px solid #4a44f2;
  box-sizing: border-box;
  color: #fff;
  width: 140px;
}

.red {
  color: #da0000;
}

.green {
  color: #429e4b;
}

.yellow {
  color: #ffb528;
}

.status {
  margin-left: 10px;
}
</style>
