import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "@/components/dashboard";
import Exchange from "@/components/exchange";
import History from "@/components/history";

import Login from "@/components/login";
import applicationForm from "@/components/applicationForm";

import Settings from "../components/settings/index.vue";
import BankAccounts from "../components/settings/BankAccounts.vue";
import Info from "../components/settings/Info.vue";
import TwoFactor from "../components/settings/TwoFactor.vue";
import ChangePassword from "../components/settings/ChangePassword.vue";
import Deposit from "../components/deposit/index.vue";
import DepositOverview from "../components/deposit/DepositOverview.vue";
import DepositBankTransfer from "../components/deposit/BankTransfer.vue";
import DepositCryptoCurrency from "../components/deposit/Cryptocurrency.vue";
import Withdrawal from "../components/withdrawal/index.vue";
import WithdrawalOverview from "../components/withdrawal/WithdrawalOverview.vue";
import WithdrawalBankTransfer from "../components/withdrawal/BankTransfer.vue";
import WithdrawalCryptoCurrency from "../components/withdrawal/Cryptocurrency.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: { title: "Dashboard" },
  },
  {
    path: "/exchange",
    name: "exchange",
    component: Exchange,
    meta: { title: "Exchange" },
  },
  {
    path: "/history",
    name: "history",
    component: History,
    meta: { title: "History" },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { title: "Login" },
  },
  {
    path: "/applicationForm",
    name: "applicationForm",
    component: applicationForm,
    meta: { title: "Application Form" },
  },
  {
    path: "/deposit",
    name: "deposit",
    component: Deposit,
    children: [
      {
        path: "overview",
        name: "overview",
        component: DepositOverview,
        meta: { title: "Overview" },
      },
      {
        path: "bank-transfer",
        name: "bankTransfer",
        component: DepositBankTransfer,
        meta: { title: "Bank Transfer" },
      },
      {
        path: "cryptocurrency",
        name: "cryptocurrency",
        component: DepositCryptoCurrency,
        meta: { title: "Cryptocurrency" },
      },
    ],
  },
  {
    path: "/withdrawal",
    name: "withdrawal",
    component: Withdrawal,
    children: [
      {
        path: "overview",
        name: "overview",
        component: WithdrawalOverview,
        meta: { title: "Overview" },
      },
      {
        path: "bank-transfer",
        name: "bankTransfer",
        component: WithdrawalBankTransfer,
        meta: { title: "Bank Transfer" },
      },
      {
        path: "cryptocurrency",
        name: "cryptocurrency",
        component: WithdrawalCryptoCurrency,
        meta: { title: "Cryptocurrency" },
      },
    ],
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    children: [
      {
        path: "bank-accounts",
        name: "bankAccounts",
        component: BankAccounts,
        meta: { title: "Bank Accounts" },
      },
      {
        path: "info",
        name: "info",
        component: Info,
        meta: { title: "Info and docs" },
      },
      {
        path: "2factor",
        name: "2factor",
        component: TwoFactor,
        meta: { title: "Two factor authentificaton" },
      },
      {
        path: "change-password",
        name: "changePassword",
        component: ChangePassword,
        meta: { title: "Change Password" },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to) {
    if (to.hash === "#") return { x: 0, y: 1500 };
    if (to.hash === "#") return { x: 0, y: 2100 };
    document.title = "OERO Exchange | " + to.meta.title || "OERO Exchange";
    return { x: 0, y: 0 };
  },
});

export default router;
