<template>
  <footer class="footer" id="footer-sec">
    <div class="footer__wraper">
      <ul class="footer__list">
        <li class="footer__list-item">
          <img
            src="@/assets/x-pay-logo.svg"
            class="logo-img"
          />
        </li>
        <li class="footer__list-item">
          <!-- <ul class="footer__link-block link-block">
            <li class="link-block__item">
              <a
                href="https://xpayexchange.com/"
                target="_blank"
                data-module="footer"
                data-name="footer-001"
              >
                exchange page
              </a>
            </li>
            <li class="link-block__item">
              <a
                href="https://xpayexchange.com/legal.html"
                target="_blank"
                data-module="footer"
                data-name="footer-002"
              >
                legal
              </a>
            </li>
            <li class="link-block__item">
              <a
                href="https://xpayexchange.com/faq.html"
                target="_blank"
                data-module="footer"
                data-name="footer-003"
              >
                F.A.Q.
              </a>
            </li>
            <li class="link-block__item">
              <a
                href="https://xpayexchange.com/index.html#advantages"
                target="_blank"
                data-module="footer"
                data-name="footer-004"
              >
                benefits
              </a>
            </li>
          </ul> -->
        </li>
        <li class="footer__list-item">
          <span data-module="footer" data-name="footer-006">address:</span>
          <span data-module="footer" data-name="footer-007">
            PARDA TN 5-413, KESKLINNA LINNAOSA, TALLINN, HARJU MAAKOND, 10151 LICENSES: FVT000089
          </span>
        </li>
        <li class="footer__list-item">
          <span data-module="footer" data-name="footer-008"
            >company e-mail:</span
          >
          <span>OEROCOIN@GMAIL.COM</span>
          <span>company@oero.io</span>
        </li>
        <li class="footer__list-item">
          <span data-module="footer" data-name="footer-009">phone number:</span>
          <span>+372 81 937 708</span>
        </li>
      </ul>
      <p class="footer__descrip">
        <span>&copy; </span>
        <span data-module="footer" data-name="footer-010"
          >k trade international ltd</span
        >
      </p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 260px;
}

.footer__wraper {
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding: 50px 0 30px 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-flow: column nowrap;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  align-items: flex-start;
}

.footer__list {
  list-style-type: none;
  padding-left: 0;
  width: 100%;
  height: calc(100% - 17px);
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row nowrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  align-items: flex-start;
}

.footer__list-item {
  height: 100%;
}

.footer__list-item:nth-of-type(1) {
  width: 72px;
  height: auto;
  margin-bottom: 20px;
  text-align: center;
}
.logo-img {
  width: 72px;
  height: 59px;
}
/* logo-img прописан в header.css */

.footer__list .footer__list-item:nth-of-type(2) {
  width: auto;
}

.footer__link-block {
  list-style-type: none;
  padding-left: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-flow: column nowrap;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  align-items: flex-start;
  text-transform: uppercase;
  font-family: "WorkSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #0e0a19;
}

.footer__link-block .link-block__item {
  margin-bottom: 15px;
}

/* link-block прописан в header.css*/

.footer__list-item:nth-of-type(3) {
  width: 300px;
}

.footer__list-item:nth-of-type(4) {
  width: 150px;
}

.footer__list-item:nth-of-type(5) {
  width: 125px;
}

.footer__list-item > span {
  display: block;
  margin-bottom: 15px;
  font-family: "WorkSans-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #0e0a19;
}
.footer__descrip {
  display: block;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-family: "WorkSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #0e0a19;
}
@media (max-width: 1250px) {
  .footer {
    height: auto;
  }
  .footer__wraper {
    padding: 50px 30px 30px 30px;
  }
  /* .footer__list {
    flex-direction: column;
    justify-content: center;
    flex-flow: column wrap;
    width: 600px;
    align-items: center;
	} */
  .footer__list {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .footer__list .footer__list-item:nth-of-type(2) {
    width: calc(100% - 72px);
    padding-left: 50px;
  }
  .footer__link-block {
    flex-direction: row;
    justify-content: space-around;
  }
  .link-block__item {
    margin-top: 20px;
  }
}
@media (max-width: 1000px) {
  /* .footer__wraper {
    padding: 20px 0 30px 0;
  } */
  .footer {
    background: none;
  }
}
@media (max-width: 800px) {
  .footer {
    height: auto;
  }
  .footer__list .footer__list-item:nth-of-type(1) {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
  .footer__link-block {
    font-size: 17px;
  }
  .footer__list .footer__list-item:nth-of-type(2) {
    width: 100%;
  }
  .footer__list-item:nth-of-type(3) {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .footer__list-item:nth-of-type(4) {
    width: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .footer__list-item:nth-of-type(5) {
    width: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .footer__link-block {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  .link-block__item {
    margin-top: 0;
  }
}

@media (max-width: 640px) {
  .footer__list {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
  .footer__list-item > span {
    font-size: 14px;
  }
  .footer__list-item > span:last-of-type {
    font-size: 12px;
  }
  .footer__link-block {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
  .footer__link-block {
    font-size: 16px;
  }
  .footer__list .footer__list-item:nth-of-type(2) {
    width: 100%;
    padding-left: 0;
  }
  .footer__list-item:nth-of-type(3) {
    width: 100%;
  }
  .footer__list-item:nth-of-type(3) > span {
    text-align: center;
  }
  .footer__list-item:nth-of-type(4) {
    width: 100%;
    margin-bottom: 30px;
  }
  .footer__list-item:nth-of-type(5) {
    width: 100%;
  }
  .footer__link-block {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  .link-block__item {
    margin-top: 0;
  }
}
</style>
